import React, { Component } from 'react';
import logo from './123banklogo.png';
import './App.scss';
import awsConfig from './config.json';
import {CognitoAuth} from 'amazon-cognito-auth-js';


class App extends Component {


  render() {
    var auth = new CognitoAuth(awsConfig.Auth);
    auth.useCodeGrantFlow();
    const loginUrl = 'https://idp.123-bank.com/oauth2/authorize?response_type=code&client_id=1c3vm1v9091bbqbcupc8invn84&redirect_uri=https://www.123-bank.com/callback&state=12345&scope=openid';
    console.log("Hello");
    console.log(awsConfig);
    return (
      <>
      <section className="hero is-primary">
	      <div className="hero-body">
              <div>
                <figure className="image container is-256x256">
                  <img src={logo} alt=""></img>
                  </figure>
                <div className="container has-text-centered">
                
                <h1 className="title">Backoffice</h1>
                <p className="subtitle">Open Credit Stage</p>
                <a className="button" href={loginUrl}>Login</a>
                </div>
              </div>
        </div>
      </section>
      <footer className="footer">
      <div className="content has-text-centered">
        <p>
          <strong>OpenCredit</strong> by <a href="https://collenda.com">Collenda</a>.
        </p>
      </div>
    </footer>
    </>
    );
  }
}

export default App;
